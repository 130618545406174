import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { Section } from '../components/section';
import { Anchors } from '../components/navigation';
import styles from './index.module.scss';
import MainImg from '../images/main.inline.svg';
import AdviseImg from '../images/advise.inline.svg';
import BuildImg from '../images/build.inline.svg';
import DesignImg from '../images/design.inline.svg';
import MaintainImg from '../images/maintain.inline.svg';

const IndexPage = ({ location }: { location: Location }) => (
  <Layout location={location}>
    <SEO title="SimpleSoft software house" lang="pl" description="aplikacje biznesowe, webowe i mobilne, tworzenie oprogramowania, software house" />
    <Section anchor={Anchors.intro} className={styles.intro}>
      <h1>Dedykowane rozwiązania</h1>
      <div>
        <div className={styles.infoImage}>
          <MainImg width="100%" height="100%" />
        </div>
        <div className={styles.desc}>
          <p>
            Jesteśmy młodą firmą stworzoną przez ludzi dla których programowanie to nie tylko praca ale i pasja.
            Wszytko dobierzemy w zależności od problemu, projektu i Twoich potrzeb.
            Wybieramy odpowiednie narzędzia do rozwiązania Twoich problemów - możemy stworzyć oprogramowanie od zera lub użyć i wdrożyć już istniejące rozwiązania.
            Dla nas najważniejsze jest by dostarczyć to co zamówiłeś i na tym się skupiamy.
        </p>
          <p>
            Zaczynając od specyfikacji wymagań, poprzez rozwój, doradztwo aż po pomoc w utrzymaniu aplikacji - oferujemy wszystko co jest potrzebne zarówno dla małych jak i dużych projektów.
        </p>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.services} className={styles.services}>
      <h1>Usługi</h1>
      <div className={styles.service}>
        <h3>Doradzamy</h3>
        <div>
          <AdviseImg />
          <p>
            Wszystkie projekty zaczynają się od pomysłu.
            Pomagamy przekształcić pomysł w konkretne wymagania oraz strategię rozwoju aplikacji.
            Doradzamy w wyborze odpowiedniej technologii, usług zewnętrznych oraz ustalamy grafik prac.
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Projektujemy </h3>
        <div>
          <DesignImg />
          <p>
            Dobry design to coś więcej niż sam wygląd. Intuicyjny, łatwy w obsłudze i dobrze wyglądający interfejs użytkownika jest czymś, co może zadecydować o sukcesie Twojego projektu.
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Rozwijamy</h3>
        <div>
          <BuildImg />
          <p>
            To nasza główna działalność. Tworzymy i rozwijamy aplikacje mając na uwadze jej przyszły sukces.
            Dzięki metodologii agile i automatyzacji jesteśmy w stanie dać Ci niemal natychmiastowy podgląd na wszystkie zmiany jakie zaszły w projekcie.
            To pomaga nam reagować na Twoje uwagi tak szybko jak to możliwe.
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Utrzymujemy</h3>
        <div>
          <MaintainImg />
          <p>
            Nasze aplikacje są projektowane tak, by mogły w łatwy sposób się skalować oraz być dostępne przez długi okres, jednak wymagania zmieniają się z czasem.
            Pomagamy w utrzymaniu Twojego projektu, aby zawsze działał tak jak to zostało zaplanowane.
        </p>
        </div>
      </div>

      <div className={styles.tech}>
        <div className={styles.scope}>
          <div>
            <h4>Obszary w których działamy</h4>
            <ul>
              <li>Aplikacje biznesowe</li>
              <li>Rozwiązania chmurowe</li>
              <li>Aplikacje internetowe</li>
              <li>Aplikacje mobilne</li>
            </ul>
          </div>
        </div>
        <div className={styles.stack}>
          <h4>Stos technologiczny</h4>
          <ul>
            <li>C#</li>
            <li>F#</li>
            <li>TypeScript</li>
            <li>JavaScript</li>
            <li>React</li>
            <li>Angular</li>
            <li>.Net Core</li>
            <li>Node.js</li>
            <li>React Native</li>
            <li>Python</li>
            <li>MSSql</li>
            <li>PostgreSQL</li>
            <li>Azure</li>
          </ul>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.principles}>
      <h1>Nasze zasady</h1>
      <div className={styles.principles}>
        <div>
          <h3>Szybka pętla zwrotna.</h3>
          <p>
            Sposób w jaki pracujemy wymusza na nas ciągłe prezentowanie Ci zmian jakie zaszły w projekcie. Chcemy, abyś był w stanie widzieć postęp prac i abyś mógł dać nam odpowiedź zwrotną jak najszybciej.
          </p>
        </div>
        <div>
          <h3>Automatyzacja</h3>
          <p>
            Mocno wierzymy, że proces tworzenia aplikacji powinien być maksymalnie zautomatyzowany. 
            Wypracowaliśmy swój sposób na codzienne wdrażanie zmian, a automatyczne testy aplikacji minimalizują ryzyko wystąpienia błędów.
            {/* {This reduces costs and allow us to give best quality product.}  */}
          </p>
        </div>
        <div>
          <h3>Podejście indywidualne</h3>
          <p>
            Zawsze dostosowujemy się do Twoich potrzeb i budżetu. Każdy projekt jest poprzedzony dogłębną analizą.
          </p>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.contact}>
      <h1>Kontakt</h1>
      <div className={styles.contact}>
        SimpleSoft <br />
        Robotnicza 72/225<br />
        53-608 Wrocław<br />
        Poland<br />
        <a href="tel://+48 697 321 812">+48 697 321 812</a>
        <br />
        <a href="mail://contact@simplesoft.tech">contact@simplesoft.tech</a>
      </div>
    </Section>
    <Section anchor={Anchors.career}>
      <h1>Kariera</h1>
      <div className={styles.career}>
        <h3>Obecne otwarte pozycje:</h3>
        <ul>
          <li>
            <a href="/dotnet-developer">.NET developer</a>
          </li>
          <li>
            <a href="/devops">Devops</a>
          </li>
          <li>
            <a href="/trainee">Stażysta</a>
          </li>
        </ul>
        <h3>Zawsze szukamy utalentowanych ludzi!</h3>
        <p>Wyślij do nas swoje CV lub link do profilu w serwisie który pokaże nam kim jesteś na <a href="mailto://job@simplesoft.tech">job@simplesoft.tech</a></p>
      </div>
    </Section>
    {/* <AniLink cover bg="#cce" duration={0.7} to="/page-2/">
      Go to page 2
    </AniLink> */}
  </Layout>
);

export default IndexPage;
